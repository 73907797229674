import { PropertyTypeFilter } from '../../../__generated__/api-types'

export const resultsPerPage = 30
export const enableBookTourCarouselFilterCard = false
export const houseLandingPagePropertyTypes = [
  PropertyTypeFilter.Houses,
  PropertyTypeFilter.Duplexes,
  PropertyTypeFilter.Triplexes,
  PropertyTypeFilter.Townhouses,
]
export const enableRecentlyUpdatedCarousel = true
export const propertyTypeMap = {
  APARTMENTS: 'Apartment',
  HOUSE: 'House',
  TOWNHOME: 'Townhouse',
  CONDO: 'Condo',
  DUPLEX: 'Duplex',
  TRIPLEX: 'Triplex',
}
